import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defer={false}
    >
      <html lang="en"/>
      {/*
      <script type="text/javascript" src="http://10.0.8.60/javascripts/api/tableau-2.8.0.min.js"></script>
      */}
      <meta name="description" content={metaDescription}/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:type" content={`website`}/>
      <meta name="twitter:card" content={`summary`}/>
      <meta name="twitter:creator" content={site.siteMetadata.author}/>
      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={metaDescription}/>
      <script type="text/javascript">{`
var _mtm = window._mtm = window._mtm || [];
_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
g.type='text/javascript'; g.async=true; g.src='https://mato.fstrf.org/matomo/js/container_vTOz1i34.js'; s.parentNode.insertBefore(g,s);
      `}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
