import React from "react";
import tw from "twin.macro";

import Navbar from "./navbar";

const HeaderContainer = tw.header``;

const Header = () => (
  <HeaderContainer>
    <Navbar />
  </HeaderContainer>
);

export default Header;
