import React from "react";
import tw from "twin.macro";
import logoSrc from "./cp-ctnet-badge-color.svg";

const FooterContainer = tw.footer`border-t border-blue-900`;
const FooterWrapper = tw.div`container mx-auto my-4 p-6 md:px-0`;
const FooterGrid = tw.div`grid md:grid-cols-3 gap-8`;
const FooterText = tw.p`font-semibold`;
const FooterSmallText = tw(FooterText)`text-xs tracking-wide`;
const FooterLink = tw.a`underline hocus:text-blue-700`;
const Button = tw.a`inline-block rounded transition-all py-2 px-3 font-normal border border-blue-700 hocus:text-white hocus:bg-blue-700`;
const FooterCol = tw.div`space-y-4`;
const BadgeLink = tw.a``;
const Badge = tw.img`max-w-xs`;

const Footer = () => (
  <FooterContainer>
    <FooterWrapper>
      <FooterGrid>
        <FooterCol>
          <BadgeLink href="https://prevention.cancer.gov/major-programs/cancer-prevention-clinical-trials-network">
            <Badge src={logoSrc} alt="CT-CTNet NCI Badge" />
          </BadgeLink>
        </FooterCol>
        <FooterCol>
          <FooterText>
            This project is supported by the National Cancer Institute (NCI) of
            the National Institutes of Health under award number U24CA242637.
            The content is solely the responsibility of the authors and does not
            necessarily represent the official view of the National Institutes
            of Health.
          </FooterText>
        </FooterCol>
        <FooterCol>
          <Button href="https://prevention.cancer.gov/major-programs/cancer-prevention-clinical-trials-network">
            Visit the CP-CTNet Website
          </Button>
          <FooterSmallText>
            Copyright &copy; {new Date().getFullYear()},{" "}
            <FooterLink href="https://www.frontierscience.org">
              Frontier Science and Technology Research Foundation, Inc.
            </FooterLink>{" "}
            All rights reserved.
          </FooterSmallText>
          <FooterSmallText>
            <FooterLink
              target="_blank"
              href="https://www.frontierscience.org/legal/privacy-notice.html?site=cpctnet"
            >
              Privacy and Terms of Use
            </FooterLink>
          </FooterSmallText>
        </FooterCol>
      </FooterGrid>
    </FooterWrapper>
  </FooterContainer>
);

export default Footer;
