import React from "react";
import PropTypes from "prop-types";
import tw from "twin.macro";

import Header from "./header";
import Footer from "./footer";

const LayoutContainer = tw.div`bg-gray-50 font-sans min-h-screen flex flex-col`;
const Main = tw.main`flex-1 flex flex-col`;

export const Table = tw.table`w-full`
export const TableHead = tw.thead`bg-blue-900 text-white`
export const TableBody = tw.tbody``
export const TableRow = tw.tr``
export const TableCell = tw.td`px-4 py-2 align-top`
export const TableHeading = tw.th`px-4 py-2 align-top text-left`

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </LayoutContainer>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
